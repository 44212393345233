module.exports = {
    'admin-menu.item.categories': 'Email csomagok',
    'admin-menu.item.templates': 'Email sablonok',
    'admin-menu.item.shops': 'Webáruházak',
    'admin-menu.item.shops-for-search': 'Webáruházak kereséshez',
    'admin-menu.item.performance': 'Összes levél hatékonysága',
    'admin-menu.item.performance-by-template': 'Levelentkénti hatékonyság',
    'admin-menu.item.custom-newsletters': 'Egyedi hírlevelek',
    'admin-menu.item.integration-stat': 'Havi elszámolás',
    'admin-menu.item.business-report-export': 'Üzleti riport export',
    'admin-menu.item.shoprenter-apps': 'Shoprenter Appok',
    'admin-menu.item.shoprenter-invoices': 'Shoprenter díjelszámoló',
    'admin-menu.item.generated-reports': 'Heti, havi statisztikák',
    'admin-menu.item.invite-manager-to-all-shops': 'Kezelő meghívása mindenhova',
};

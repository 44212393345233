"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatsModal = void 0;
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var chart_js_1 = require("chart.js");
var react_chartjs_2_1 = require("react-chartjs-2");
var chartjs_plugin_datalabels_1 = require("chartjs-plugin-datalabels");
var state_repository_1 = require("../../../common/libs/state-repository");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
chart_js_1.Chart.register(chart_js_1.ArcElement, chart_js_1.Tooltip, chart_js_1.Legend, chartjs_plugin_datalabels_1.default);
var getEmptyLevel = function (level) {
    return {
        amount: 0,
        count: 0,
        relatedToMILevel: level
    };
};
var formatRawDataForPieChart = function (rawData) {
    var _a, _b, _c, _d;
    var formattedData = [];
    formattedData[0] = {
        amount: (((_a = rawData.find(function (object) { return object.relatedToMILevel === null; })) === null || _a === void 0 ? void 0 : _a.amount) || 0) + (((_b = rawData.find(function (object) { return object.relatedToMILevel === 0; })) === null || _b === void 0 ? void 0 : _b.amount) || 0),
        count: (((_c = rawData.find(function (object) { return object.relatedToMILevel === null; })) === null || _c === void 0 ? void 0 : _c.count) || 0) + (((_d = rawData.find(function (object) { return object.relatedToMILevel === 0; })) === null || _d === void 0 ? void 0 : _d.count) || 0),
        relatedToMILevel: 0
    };
    formattedData[1] = rawData.find(function (object) { return object.relatedToMILevel === 1; }) || getEmptyLevel(1);
    formattedData[2] = rawData.find(function (object) { return object.relatedToMILevel === 2; }) || getEmptyLevel(2);
    formattedData[3] = rawData.find(function (object) { return object.relatedToMILevel === 3; }) || getEmptyLevel(3);
    return formattedData.filter(function (i) { return !!i; });
};
var formatAsPercentage = function (value, all) {
    return (value / all * 100).toFixed(2) + "%";
};
var formatLabelsForPieChart = function (rawData) {
    var _a, _b, _c, _d;
    var formattedLabels = [];
    var sumCounts = (rawData || []).reduce(function (previous, current) {
        return previous + current.count;
    }, 0);
    var valueForLevel0 = {
        amount: (((_a = rawData.find(function (object) { return object.relatedToMILevel === null; })) === null || _a === void 0 ? void 0 : _a.amount) || 0) + (((_b = rawData.find(function (object) { return object.relatedToMILevel === 0; })) === null || _b === void 0 ? void 0 : _b.amount) || 0),
        count: (((_c = rawData.find(function (object) { return object.relatedToMILevel === null; })) === null || _c === void 0 ? void 0 : _c.count) || 0) + (((_d = rawData.find(function (object) { return object.relatedToMILevel === 0; })) === null || _d === void 0 ? void 0 : _d.count) || 0),
        relatedToMILevel: 0
    };
    if (valueForLevel0) {
        formattedLabels[0] = "Nem k\u00F6thet\u0151 (" + formatAsPercentage(valueForLevel0.count, sumCounts) + ")";
    }
    else {
        formattedLabels[0] = "Nem k\u00F6thet\u0151 (0%)";
    }
    var valueForLevel1 = rawData.find(function (object) { return object.relatedToMILevel === 1; });
    if (valueForLevel1) {
        formattedLabels[1] = "Feltehet\u0151en (" + formatAsPercentage(valueForLevel1.count, sumCounts) + ")";
    }
    else {
        formattedLabels[1] = "Feltehet\u0151en (0%)";
    }
    var valueForLevel2 = rawData.find(function (object) { return object.relatedToMILevel === 2; });
    if (valueForLevel2) {
        formattedLabels[2] = "Er\u0151sen (" + formatAsPercentage(valueForLevel2.count, sumCounts) + ")";
    }
    else {
        formattedLabels[2] = "Er\u0151sen (0%)";
    }
    var valueForLevel3 = rawData.find(function (object) { return object.relatedToMILevel === 3; });
    if (valueForLevel3) {
        formattedLabels[3] = "Bizony\u00EDthat\u00F3an (" + formatAsPercentage(valueForLevel3.count, sumCounts) + ")";
    }
    else {
        formattedLabels[3] = "Bizony\u00EDthat\u00F3an (0%)";
    }
    return formattedLabels;
};
var OrderStatsModal = (function (_super) {
    __extends(OrderStatsModal, _super);
    function OrderStatsModal(props) {
        var _this = _super.call(this, props) || this;
        _this.order = new state_repository_1.StateRepository(repository_1.Repository.use('default'), _this, 'order');
        _this.state = __assign(__assign({}, _this.state), { isLoading: false, error: null, data: [] });
        _this.fetchOrderStats = function () { return __awaiter(_this, void 0, void 0, function () {
            var response, e_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        this.setState({ isLoading: true });
                        return [4, this.order.get("/s/" + ((_a = this.props.currentShop) === null || _a === void 0 ? void 0 : _a._id) + "/data/temporary/order/summary?query=" + (this.props.query ? this.props.query : '') + "&from=" + this.props.from + "&to=" + this.props.to, {})];
                    case 1:
                        response = _b.sent();
                        if (response === null || response === void 0 ? void 0 : response.summary) {
                            this.setState({ data: response.summary });
                        }
                        this.setState({ isLoading: false });
                        return [3, 3];
                    case 2:
                        e_1 = _b.sent();
                        return [2, this.setState({ isLoading: false, error: e_1 })];
                    case 3: return [2];
                }
            });
        }); };
        return _this;
    }
    OrderStatsModal.prototype.componentDidMount = function () {
        this.fetchOrderStats();
    };
    OrderStatsModal.prototype.render = function () {
        var _this = this;
        if (this.state.isLoading) {
            return (React.createElement("div", { className: 'Loader' },
                React.createElement(semantic_ui_react_1.Loader, { active: true, inline: 'centered', size: 'big' })));
        }
        if (this.state.isError || this.state.data === undefined) {
            return (React.createElement("div", null, "Nincsenek el\u00E9rhet\u0151 statisztikai adatok."));
        }
        else if (this.state.isLoading) {
            return (React.createElement("div", null, "Adatok bet\u00F6lt\u00E9s alatt..."));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(semantic_ui_react_1.Modal.Header, { key: 0 }, "Rendel\u00E9sek \u00F6sszegz\u00E9se"),
            React.createElement(semantic_ui_react_1.Modal.Content, { key: 1 },
                React.createElement("div", { style: {
                        height: "70vh"
                    } },
                    React.createElement(react_chartjs_2_1.Pie, { data: {
                            labels: formatLabelsForPieChart(this.state.data || []),
                            datasets: [
                                {
                                    label: 'Bevétel összege, darabszám',
                                    data: formatRawDataForPieChart(this.state.data || []).map(function (object) { return (object === null || object === void 0 ? void 0 : object.amount) || 0; }),
                                    backgroundColor: [
                                        'rgba(189, 189, 189, 1)',
                                        'rgba(251, 189, 8, 1)',
                                        'rgba(15, 18, 52, 1)',
                                        'rgba(33, 186, 69, 1)'
                                    ],
                                    borderColor: [
                                        'rgba(189, 189, 189, 1)',
                                        'rgba(251, 189, 8, 1)',
                                        'rgba(15, 18, 52, 1)',
                                        'rgba(33, 186, 69, 1)'
                                    ],
                                    borderWidth: 1,
                                },
                            ],
                        }, options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                datalabels: {
                                    color: 'black',
                                    labels: {
                                        value: {
                                            color: 'black',
                                            backgroundColor: 'white',
                                            font: {
                                                size: 24
                                            }
                                        }
                                    },
                                    formatter: function (value, context) {
                                        var _a;
                                        var currency = new Intl.NumberFormat("hu-HU", {
                                            style: "currency",
                                            currency: "HUF",
                                        }).format(value);
                                        var count = ((_a = formatRawDataForPieChart(_this.state.data)[context.dataIndex]) === null || _a === void 0 ? void 0 : _a.count) || 0;
                                        return currency + ", " + count + " darab";
                                    },
                                    anchor: 'center',
                                    align: 'end',
                                    textAlign: 'end'
                                },
                                legend: {
                                    position: 'top',
                                    labels: {
                                        font: {
                                            size: 16
                                        }
                                    }
                                }
                            }
                        }, plugins: [chartjs_plugin_datalabels_1.default] })))));
    };
    return OrderStatsModal;
}(abstract_component_1.AbstractComponent));
exports.OrderStatsModal = OrderStatsModal;

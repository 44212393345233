"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoicesWithPopulatedPayments = void 0;
var React = require("react");
var semantic_ui_react_1 = require("semantic-ui-react");
var abstract_component_1 = require("@codebuild/uikit/libs/libs/abstract.component");
var repository_1 = require("@codebuild/uikit/libs/repository/repository");
var environment_1 = require("@codebuild/uikit/libs/environment");
var axios_1 = require("axios");
var get_access_token_1 = require("@codebuild/uikit/modules/codebuild/authentication/libs/get-access-token");
var toast_util_1 = require("../../../shoprenter/errors/toast.util");
var InvoicesWithPopulatedPayments = (function (_super) {
    __extends(InvoicesWithPopulatedPayments, _super);
    function InvoicesWithPopulatedPayments() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.shop = repository_1.Repository.use('default');
        _this.state = {
            isLoading: true,
            isError: false,
            isSuccess: false,
            invoicesWithPopulatedPayments: null
        };
        return _this;
    }
    InvoicesWithPopulatedPayments.prototype.getShopInvoicesWithPopulatedPayments = function () {
        return __awaiter(this, void 0, void 0, function () {
            var baseUrl, url, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({ isLoading: true, isError: false, isSuccess: false });
                        baseUrl = environment_1.Environment.get('api');
                        url = baseUrl + "/s/" + this.props.shopId + "/shop/bigfish/invoices-with-populated-payments?shopId=" + this.props.shopId;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4, axios_1.default.get(url, {
                                headers: {
                                    Authorization: get_access_token_1.getAccessToken()
                                }
                            })];
                    case 2:
                        response = _a.sent();
                        this.setState({ invoicesWithPopulatedPayments: response.data, isSuccess: true });
                        console.log('getShopInvoicesWithPopulatedPayments invoicesWithPopulatedPayments:', response.data);
                        return [3, 5];
                    case 3:
                        error_1 = _a.sent();
                        console.log('getShopInvoicesWithPopulatedPayments error:', error_1);
                        toast_util_1.openErrorToastWithMessage("A számlák és fizetések betöltése közben hiba történt.");
                        this.setState({ isError: true });
                        return [3, 5];
                    case 4:
                        this.setState({ isLoading: false });
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    InvoicesWithPopulatedPayments.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.getShopInvoicesWithPopulatedPayments();
                return [2];
            });
        });
    };
    InvoicesWithPopulatedPayments.prototype.render = function () {
        if (this.state.isLoading) {
            return React.createElement(semantic_ui_react_1.Loader, { active: true, inline: true, size: 'big' }, "Sz\u00E1ml\u00E1k bet\u00F6lt\u00E9se");
        }
        if (this.state.isError) {
            return React.createElement("p", null, "A sz\u00E1ml\u00E1k bet\u00F6lt\u00E9se k\u00F6zben hiba t\u00F6rt\u00E9nt.");
        }
        if (this.state.isSuccess) {
            if (this.state.invoicesWithPopulatedPayments.length > 0) {
                return (this.state.invoicesWithPopulatedPayments.map(function (invoiceWithPopulatedPayments) {
                    var _id = invoiceWithPopulatedPayments._id, createdAt = invoiceWithPopulatedPayments.createdAt, paid = invoiceWithPopulatedPayments.paid, populatedPayments = invoiceWithPopulatedPayments.populatedPayments, populatedShopFee = invoiceWithPopulatedPayments.populatedShopFee, url = invoiceWithPopulatedPayments.url;
                    var paymentsCount = populatedPayments.length;
                    var icon = paid ? "checkmark" : "times circle";
                    var iconColor = paid ? "green" : "red";
                    var hasMorePayments = paymentsCount > 1;
                    var transformedPayments = populatedPayments.map(function (payment) {
                        var _a, _b;
                        var isPaid = ((_a = payment === null || payment === void 0 ? void 0 : payment.Result) === null || _a === void 0 ? void 0 : _a.ResultCode) === "SUCCESSFUL" || false;
                        var status = isPaid ? "PAID" : "ERROR";
                        var message = (_b = payment === null || payment === void 0 ? void 0 : payment.Result) === null || _b === void 0 ? void 0 : _b.ResultMessage;
                        return __assign(__assign({}, payment), { isPaid: isPaid,
                            status: status,
                            message: message });
                    });
                    var firstPayment = transformedPayments[0];
                    var otherPayments = hasMorePayments ? transformedPayments.slice(1) : [];
                    var invoiceItems = [];
                    if (populatedShopFee) {
                        var maintenanceFee = populatedShopFee.maintenanceFee, subscribers = populatedShopFee.subscribers, pricePerSubscribers = populatedShopFee.pricePerSubscribers, exchangeRate = populatedShopFee.exchangeRate, exchangeRateDate = populatedShopFee.exchangeRateDate, fee = populatedShopFee.fee, feeEur = populatedShopFee.feeEur, discount = populatedShopFee.discount;
                        invoiceItems.push("\u00DCzemeltet\u00E9si d\u00EDj: " + maintenanceFee);
                        invoiceItems.push("Listakezel\u00E9si d\u00EDj (listam\u00E9ret: " + subscribers + " darab, egys\u00E9g\u00E1r: " + pricePerSubscribers + " EUR/darab, kedvezm\u00E9ny: " + discount + "%)");
                        invoiceItems.push("\u00C1tv\u00E1lt\u00E1si \u00E1rfolyam: " + exchangeRate + " (d\u00E1tum: " + exchangeRateDate + ")");
                        invoiceItems.push("Sz\u00E1mla teljes d\u00EDja: nett\u00F3 " + fee + " HUF / " + feeEur + " EUR");
                    }
                    return (React.createElement(React.Fragment, null,
                        React.createElement(semantic_ui_react_1.TableRow, { key: _id },
                            React.createElement(semantic_ui_react_1.TableCell, { rowSpan: paymentsCount }, createdAt),
                            React.createElement(semantic_ui_react_1.TableCell, { rowSpan: paymentsCount },
                                React.createElement("ul", null, invoiceItems.map(function (invoiceItem) { return React.createElement("li", { key: invoiceItem }, invoiceItem); }))),
                            React.createElement(semantic_ui_react_1.TableCell, { textAlign: 'center', rowSpan: paymentsCount },
                                React.createElement(semantic_ui_react_1.Icon, { color: iconColor, name: icon, size: 'large' })),
                            React.createElement(semantic_ui_react_1.TableCell, { textAlign: 'center', rowSpan: paymentsCount },
                                React.createElement("a", { href: url || "", download: url || "", target: '_blank' },
                                    React.createElement(semantic_ui_react_1.Icon, { name: 'download', size: 'large', disabled: !url }))),
                            firstPayment ? (React.createElement(React.Fragment, null,
                                React.createElement(semantic_ui_react_1.TableCell, { textAlign: 'center' }, firstPayment.createdAt),
                                React.createElement(semantic_ui_react_1.TableCell, { textAlign: 'center' },
                                    React.createElement(semantic_ui_react_1.Icon, { color: firstPayment.status === "PAID" ? "green" : "red", name: firstPayment.status === "PAID" ? "checkmark" : "times circle", size: 'large' })),
                                React.createElement(semantic_ui_react_1.TableCell, null,
                                    firstPayment.message,
                                    firstPayment.status === "ERROR" && (firstPayment === null || firstPayment === void 0 ? void 0 : firstPayment.nextPaymentScheduledAt) &&
                                        ", k\u00F6vetkez\u0151 pr\u00F3b\u00E1lkoz\u00E1s: " + firstPayment.nextPaymentScheduledAt))) : (React.createElement(React.Fragment, null,
                                React.createElement(semantic_ui_react_1.TableCell, { colSpan: '3' }, "Automatikus bankk\u00E1rty\u00E1s terhel\u00E9s fog t\u00F6rt\u00E9nni.")))),
                        hasMorePayments && (otherPayments.map(function (payment) {
                            var paymentId = payment.id, paymentCreatedAt = payment.createdAt, status = payment.status, message = payment.message, nextPaymentScheduledAt = payment.nextPaymentScheduledAt;
                            var paymentIcon = status === "PAID" ? "checkmark" : "times circle";
                            var paymentIconColor = status === "PAID" ? "green" : "red";
                            return (React.createElement(semantic_ui_react_1.TableRow, { key: paymentId },
                                React.createElement(semantic_ui_react_1.TableCell, { textAlign: 'center' }, paymentCreatedAt),
                                React.createElement(semantic_ui_react_1.TableCell, { textAlign: 'center' },
                                    React.createElement(semantic_ui_react_1.Icon, { color: paymentIconColor, name: paymentIcon, size: 'large' })),
                                React.createElement(semantic_ui_react_1.TableCell, null,
                                    message,
                                    status === "ERROR" &&
                                        nextPaymentScheduledAt &&
                                        ", k\u00F6vetkez\u0151 pr\u00F3b\u00E1lkoz\u00E1s: " + nextPaymentScheduledAt)));
                        }))));
                }));
            }
            return React.createElement("p", null, "Nincsenek sz\u00E1ml\u00E1k.");
        }
        return React.createElement("p", null, "Nem tudtuk bet\u00F6lteni az adatokat: ismeretlen hiba t\u00F6rt\u00E9nt.");
    };
    return InvoicesWithPopulatedPayments;
}(abstract_component_1.AbstractComponent));
exports.InvoicesWithPopulatedPayments = InvoicesWithPopulatedPayments;
